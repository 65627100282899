import React, { createContext } from "react";
import * as ReactDOMClient from "react-dom/client";
import { Provider } from "react-redux";
// Protecting Routs
import { BrowserRouter } from "react-router-dom";
// Import root app
import App from "./containers/App/index";
import theme from "./theme";
// API calls
import axios from "axios";
import store from "./store/index";
// // import { MsalProvider } from "@azure/msal-react";
// // import { PublicClientApplication } from "@azure/msal-browser";
import types from "app-redux-types";
axios.defaults.withCredentials = false;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");

const container = document.getElementById("root");

const root = ReactDOMClient.createRoot(container);

// axios.defaults.headers.post["Content-Type"] =
//   "application/x-www-form-urlencoded";
// axios.defaults.headers.get["Content-Type"] =
//   "application/x-www-form-urlencoded";
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
// axios.defaults.headers.common["Access-Control-Allow-Headers"] =
//   "Origin, X-Requested-With, Content-Type, Accept";

// Create redux store
// const storeReduxSaga = configureStoreReduxSaga({});
// storeReduxSaga.subscribe(() => {
//   // console.log(storeReduxSaga.getState());
// });
// const MOUNT_NODE = document.getElementById("root");
// Azure AD Set up below
// const msalConfig = {
//   auth: {
//     clientId: process.env.REACT_APP_MSAL_AUTH_CLIENT_ID,
//     authority: process.env.REACT_APP_MSAL_AUTH_AUTHORITY,
//     validateAuthority: process.env.REACT_APP_MSAL_AUTH_VALIDATE_AUTHORITY,

//     navigateToLoginRequestUrl:
//       process.env.REACT_APP_MSAL_AUTH_NAVIGATE_TO_LOGIN_REQUEST_URL,
//   },
//   cache: {
//     cacheLocation: process.env.REACT_APP_MSAL_CACHE_LOCATION,
//     storeAuthStateInCookie:
//       process.env.REACT_APP_MSAL_CACHE_STORE_AUTH_STATE_IN_COOKIE,
//   },
// };
// const pca = new PublicClientApplication(msalConfig);
// Set up global state pure React
// const isDark = window.matchMedia('(prefers-color-scheme: dark)');
// if (isDark.matched === true) {
//   theme.palette.mode = "dark";
// } else {
//   theme.palette.mode = "light";
// }
// console.log(theme.palette.mode);

export const StateContext = createContext();
export const configValues = {
  error: "",
  message: "",
  signalr: {
    connectionState: "",
    connectionId: "",
  },
  codeFitBit: "",
  FitBitUser: "",
  FirstTimer: -1,
  // theme,
  Auth: {
    isAuthenticated: false,
    fullToken: {},
    token: "",
    instance: {},
    scopes: {},
    account: {},
    user: types.user,
    instance: null,
    scopes: {
      scopes: [process.env.REACT_APP_MSAL_API_SCOPES_1],
    },
  },
  fb: {
    CLIENT_ID: process.env.REACT_APP_FITBIT_CLIENT_ID,
    AUTH_URL: process.env.REACT_APP_FITBIT_AUTH_URL,
    REDIRECT_URL: process.env.REACT_APP_FITBIT_REDIRECT_URL,
    TOKEN: "",
  },
};
export const StateProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);
/////////////////// React created createContext hook to share data across application and components without using the props
// The data can be also change from any of the components and we can observe the changes

if (process.env.REACT_APP_ENV === "development") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}

root.render(
  <BrowserRouter>
    <StateContext.Provider value={configValues}>
      <Provider store={store}>
        <App />
      </Provider>
    </StateContext.Provider>
  </BrowserRouter>
);
