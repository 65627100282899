import { configureStore } from '@reduxjs/toolkit';
import mainReducer from '../slice/mainSlice';

const store = configureStore({
    reducer: {
        main: mainReducer,
    },
    devTools: process.env.NODE_ENV !== 'production',
    // devTools: true,

});

export default store;
