// create types as follows and export them:

// const types = {
//   user: {
//     name: "",
//     username: "",
//     logged: false,
//   },
// };

// export default types;

const types = {
  user: {
    name: "",
    username: "",
    logged: false,
  },
};

export default types;

