import { useState, useEffect, useCallback } from "react";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
// import { StateContext } from 'app-state';
// const GlobalInfo = {};
//https://www.youtube.com/watch?v=nEQvA5HfEDE
const useSignalRHook = (setSignalr, setMessage) => {
  const [connection, setConnection] = useState(undefined);
  const [connectionState, setConnectionState] = useState("None");
  const connectionId = {
    id: "",
  };
  const establishConnection = useCallback(async () => {
    try {
      if (typeof SignalRService?.connection?.state === "undefined") {
        const newConnection = new HubConnectionBuilder()
          .withUrl(process.env.REACT_APP_SIGNAL_R_HUB, {
            withCredentials: true,
          })
          .withAutomaticReconnect([0, 2000, 10000, 30000, null])
          .configureLogging(LogLevel.Information)
          .build();
        SignalRService.connection = newConnection;
        // console.warn(SignalRService.connection._connectionState);
        if (SignalRService?.connection?._connectionState === "Disconnected") {
          try {
            SignalRService?.connection
              .start()
              .then(() => {
                // Get connection ID here
                SignalRService.connection
                  .invoke("getConnectionId")
                  .then((id) => {
                    connectionId.id = id;
                    setConnectionState("Connected - " + connectionId.id);
                    let obj = {
                      connectionState: "Connected",
                      connectionId: id,
                    };
                    setSignalr(obj);
                    // dispatch({
                    //   type: 'change',
                    //   payload: context,
                    // });
                  });
                // context.signalr = connectionId;
              })
              .catch((e) => {
                console.error(e);
              });
          } catch (e) {
            console.error(e);
          }
        }
        setConnection(newConnection);
      }
    } catch (err) {
      console.error(err);
      setTimeout(err, 5000);
    }
  });
  const startSuccess = async () => {
    // console.log("CONNECTED TO HUB!");
  };
  const callServerMethod = async (serverMethodName) => {
    connection.send(serverMethodName);
  };
  const startFailed = async () => {
    // // // // // // // console.log("FAILED CONNECTTING TO HUB!");
  };
  const startConnection = useCallback(async () => {
    connection.start().then(startSuccess, startFailed);
  });
  const [SignalRService, setSignalRService] = useState({
    connection,
    connectionId,
    establishConnection,
  });
  useEffect(() => {
    // // // // console.log(connection);
    // // // // console.log(JSON.stringify(connection));
    // if (connection !== null) {
    //     // // // // // // // // // console.log(connection);
    // }
    if (connection !== undefined) {
      SignalRService?.connection?.onreconnecting(() => {
        // // // // // // // // // console.log("reconnecting");
        setConnectionState("reconnecting");
      });
      SignalRService?.connection?.onreconnected((id) => {
        setConnectionState("Reconnected - " + id);
        connectionId.id = id;
        let obj = {
          connectionState: "Reconnected",
          connectionId: id,
        };
        setSignalr(obj);
        // dispatch({
        //   type: "change",
        //   payload: { signalr: connectionId },
        // });
      });
      SignalRService?.connection?.onclose(() => {
        // // // // // // // // // console.log("disconnected");
        setConnectionState("Disconnected");
        let obj = {
          connectionState: "Disconnected",
          connectionId: "",
        };
        setSignalr(obj);
      });
      SignalRService?.connection?.on("ToAll", (message) => {
        // // // // // // // // console.log(message);
      });
      SignalRService?.connection?.on("ToUser", (message) => {
        console.log("SignalR ToUser", message);
        setMessage(message);
      });
      SignalRService?.connection?.on("ReceiveMessage", (message) => {
        if (SignalRService?.connection?._connectionStarted) {
          setMessage(message);
        }
      });
      SignalRService?.connection?.on("'onEvent", (message) => {
        if (SignalRService?.connection?._connectionStarted) {
          setMessage(message);
        }
      });
      // onclose(callback: (error?: Error) => void): void;
      //     /** Registers a handler that will be invoked when the connection starts reconnecting.
      //      *
      //      * @param {Function} callback The handler that will be invoked when the connection starts reconnecting. Optionally receives a single argument containing the error that caused the connection to start reconnecting (if any).
      //      */
      //     onreconnecting(callback: (error?: Error) => void): void;
      //     /** Registers a handler that will be invoked when the connection successfully reconnects.
      //      *
      //      * @param {Function} callback The handler that will be invoked when the connection successfully reconnects.
      //      */
      //     onreconnected(callback: (connectionId?: string) => void): void;
    }
  }, [connection]);
  useEffect(() => {
    // // // // // // // // console.log(connectionState);
  }, [connectionState]);
  return { SignalRService, connectionState };
};
export default useSignalRHook;
