import React, { useContext, useEffect } from 'react';
import Router from "./Router.js";
import { StateContext } from "app-entry";
import { ThemeProvider, useTheme, createTheme } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { StateProvider } from './state';
import getDesignTokens from '../../utility/themesFunctions';
const App = () => {
  const navigate = useNavigate();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const context = useContext(StateContext);
  const theme = useTheme();
  const darkModeTheme = createTheme(getDesignTokens((prefersDarkMode ? 'dark' : 'light')));
  context.theme = darkModeTheme;
  context.prefersDarkMode = prefersDarkMode;
  const reducer = (state, action) => {
    // // console.log(state);
    // // console.log(action);
    switch (action.type) {
      case 'change':
        return { ...state };
      default:
        return state;
    }
  };
  useEffect(() => {
    navigate('/registered');
  }, []);
  return (
    <StateProvider initialState={context} reducer={reducer}>
      <ThemeProvider theme={context.theme}>
        <Router />
      </ThemeProvider>
    </StateProvider>
  );
};
export default App;
