import React, { Suspense, lazy, Redirect, useContext } from "react";
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
  HashRouter,
} from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Loader from "../../components/Loader/index";
import LoginPage from "../LoginPage/index";
import { connect } from "react-redux";

const HomePageLazyLoaded = lazy(() => import("../HomePage/index"));
const NotFoundPageLazyLoaded = lazy(() => import("../NotFoundPage/index"));
const RequireAuth = ({ children, context }) => {
  let location = useLocation();
  if (!context.Auth.isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
};
const Router = (props) => (
  <Suspense fallback={Loader()}>
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/registered" element={<LoginPage />} />
      <Route
        path="/registered/home"
        element={
          <RequireAuth context={props.context}>
            <React.Suspense fallback={Loader()}>
              <HomePageLazyLoaded />
            </React.Suspense>
          </RequireAuth>
        }
      ></Route>
      <Route path="*" Navigate replace to="/registered" />
    </Routes>
  </Suspense>
);
const mapStateToProps = (state) => ({
  context: state.main,
});
export default connect(mapStateToProps, null)(Router);
