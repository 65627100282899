import React, { useState, useEffect, useContext, useCallback } from "react";
import "./login-page.css";
// import { connect } from "react-redux";
import * as api from "app-redux-api-user";
import Link from "@mui/material/Link";
import { Button, Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import ImageBackground from "../../images/biotrack-logo-611-245.png";
import AppBar from "@mui/material/AppBar";
import ImageFitBit from "../../images/fitbit-sm.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TransitionAlerts from "../../components/Alert/index";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "react-router-dom";
import { StateContext } from "app-state";
import useSignalRHook from "../../signalr/SignalRHook";
import { connect } from "react-redux";
import { setToken, setIsAuthenticated } from "../../slice/mainSlice";
const styles = {
  cardStyle: {
    opacity: 0.7,
    padding: "2px 2px 2px 2px",
    color: "grey",
    backgroundColor: "rgb(241, 241, 243)",
    // borderWidth: "0px",
    // border: "none",
  },
  fitBitImage: {
    backgroundImage: `url(${ImageFitBit})`,
    backgroundSize: "contain",
    objectFit: "cover",
    backgroundRepeat: "no-repeat",
    minHeight: "7vh",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
    border: "1px solid transparent",
    borderRadius: "4px",
    maxWidth: "100%",
    maxHeight: "calc(100vh - 64px)",
  },
  backImage: {
    // height: "80vh",
    // width: "100vw",
    marginTop: "25px",
    // marginLeft: "auto",
    // marginRight: "auto",
    // backgroundImage: `url(${ImageBackground})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    display: "flex",

    // objectFit: "cover",
    // alignItems: "center !important",
    // justifyContent: "center !important",
    // textAlign: "center !important",
    // border: "1px solid transparent",
  },
};
const LoginPage = (props) => {
  const SignalR = useSignalRHook(null);
  let navigate = useNavigate();
  const [code, setCode] = useState(
    window.location.href
      .replace(window.location.origin, "")
      .replace("/registered/?code=", "")
      .replace(window.location.hash, "")
  );
  const [context, dispatch] = useContext(StateContext);
  const [signalRId, setSignalRId] = useState(null);
  const [openAlertElement, setOpenAlertElement] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openAlertMessage, setOpenAlertMessage] = useState("");
  // const { history } = props;
  // useEffect(() => {
  //   if (typeof SignalR.SignalRService.connection === "undefined") {
  //     SignalR.SignalRService.establishConnection();
  //   } else {
  //     // // // // console.log(SignalR.SignalRService.connection.connection?.connectionId);
  //     // // // // console.log(context.signalr);
  //   }
  // }, [SignalR.SignalRService.connection]);
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    // opacity: 0.7,
    // padding: "2px 2px 2px 2px",
    color: "grey",
    backgroundColor: "rgb(241, 241, 243)",
    spacing: 4,
  }));
  const theme = createTheme();
  theme.typography.h3 = {
    fontSize: "1.2rem",
    "@media (min-width:600px)": {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2rem",
    },
  };
  useEffect(() => {
    if (code.length < 15) {
      navigate("/registered");
    } else {
      context.Auth.isAuthenticated = true;
      props.setToken(code);
      props.setIsAuthenticated(true);
      setTimeout(() => {
        navigate("/registered/home");
      }, 1000);
    }
  }, [code]);
  useEffect(() => {
    if (!!context.signalr.id) {
      setSignalRId(context.signalr.id);
    }
  }, [context]);
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    window.location.replace(window.location.origin);
    e.preventDefault();
    setCode(
      window.location.href
        .replace(window.location.origin, "")
        .replace("/registered/?code=", "")
        .replace(window.location.hash, "")
    );
    if (code.length < 15) {
      api.getUserConsent();
    } else {
      props.setToken(code);
      props.setIsAuthenticated(true);
      setTimeout(() => {
        navigate("/registered/home");
      }, 1500);
    }
  };
  const handleOpenAlertElementOnClose = useCallback(() => {
    setOpenAlertElement(false);
  });
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"xl"}>
        <AppBar
          sx={{
            minHeight: "calc(5vh)",
            maxHeight: "calc(5vh)",
            backgroundColor: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          position="fixed"
          color="primary"
          enableColorOnDark
        >
          <Typography variant="h3" sx={{ color: "lightgrey" }}></Typography>
        </AppBar>
        <Grid
          container
          spacing={1}
          align="center"
          justifyContent="center"
          alignItems="center"
          marginTop={"5vh"}
        >
          <TransitionAlerts
            open={openAlertElement}
            message={openAlertMessage}
            onClose={handleOpenAlertElementOnClose}
          />
        </Grid>
        <Grid
          container
          spacing={2}
          align="center"
          justifyContent="center"
          alignItems="center"
          sx={{
            marginTop: "calc(6vh)",
            marginBottom: "calc(1vh)",
            minHeight: "calc(10vh)",
            maxHeight: "calc(10vh)",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            align="center"
            justifyContent="center"
            alignItems="center"
          >
            <img
              src={ImageBackground}
              style={{ width: "280px", height: "120px" }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          align="center"
          justifyContent="center"
          alignItems="center"
          sx={{
            minHeight: "calc(40vh)",
            maxHeight: "calc(40vh)",
          }}
        >
          <Grid item xs={12} md={3} lg={3}>
            <Paper sx={{ padding: "20px" }} elevation={3}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <img style={{ maxWidth: "100%" }} src={ImageFitBit} />
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center">
                {!loading ? (
                  <Button
                    size="large"
                    variant="outlined"
                    onClick={handleSubmit}
                    sx={{
                      marginTop: "5px",
                      borderColor: "black",
                      color: "black",
                    }}
                  >
                    Authenticate
                  </Button>
                ) : (
                  <Skeleton animation="wave" width={"100%"} />
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Container
        maxWidth={"xl"}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={6}
          md={6}
          lg={6}
          sx={{
            marginRight: 2, // This can be any value, it is using the theme's spacing unit, which is usually 8px.
          }}
        >
          <Typography>
            <Link
              color="inherit"
              href="https://pathwayhealth.ca/terms-of-service/"
            >
              Terms of Service
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <Typography>
            <Link
              color="inherit"
              href="hhttps://pathwayhealth.ca/privacy-policy/"
            >
              Privacy Policy
            </Link>
          </Typography>
        </Grid>
      </Container>
      <Container maxWidth={"xl"}>
        <Paper
          sx={{
            minHeight: "calc(5vh)",
            maxHeight: "calc(5vh)",
            backgroundColor: "black",
            marginTop: "20%",
            position: "fixed",
            display: "flex",
            marginTop: "calc(5vh)",
            bottom: "0px",
            left: "0px",
            right: "0px",
            zIndex: 0,
            alignItems: "center",
            justifyContent: "center",
          }}
          square
        >
          <Typography
            display="block"
            variant="h3"
            sx={{ color: "lightgrey" }}
          ></Typography>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};
const mapDispatchToProps = (dispatch) => ({
  setToken: (data) => dispatch(setToken(data)),
  setIsAuthenticated: (data) => dispatch(setIsAuthenticated(data)),
});
export default connect(null, mapDispatchToProps)(LoginPage);
