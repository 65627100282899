import { grey, teal, indigo } from "@mui/material/colors";
import { blueGrey, lightBlue } from "@mui/material/colors";
const getDesignTokens = (mode) => ({
    palette: {
        mode,
        ...(mode === 'light'
            ? {
                // palette values for light mode
                main: blueGrey[500],
                divider: lightBlue[500],
                tonalOffset: 0.5,
                // contrastThreshold: 0.4,
                text: {
                    primary: grey[900],
                    secondary: teal[800],
                },
            }
            : {
                // palette values for dark mode
                primary: indigo,
                divider: indigo[700],
                tonalOffset: 0.5,
                // contrastThreshold: 0.4,
                background: {
                    default: indigo[900],
                    paper: indigo[900],
                },
                text: {
                    primary: '#fff',
                    secondary: grey[500],
                },
            }),
    },
});
export default getDesignTokens;