import { createTheme } from "@mui/material/styles";
import { blueGrey, lightBlue } from "@mui/material/colors";
// https://mui.com/customization/color/#:~:text=MUI%20provides%20all%20colors%20from%20the%20Material%20Design,pink%21%20%29%2C%20while%20%22red%20900%22%20is%20the%20darkest.
const theme = createTheme({
  palette: {
    palette: {
      mode: "dark",
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: blueGrey[500],
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
      contrastText: lightBlue[900],
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 0,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
    secondary: {
      main: lightBlue[100],
      contrastText: blueGrey[100],
    },
  },
});
theme.props = {
  MuiButton: {
    fontWeight: 700,
  },
};
theme.overrides = {
  MuiButton: {
    root: {
      fontWeight: 200,
      color: lightBlue[500],
    },
    containedPrimary: {
      "&:hover": {
        backgroundColor: blueGrey[600],
        color: lightBlue[100],
      },
    },
  },
};

export default theme;
