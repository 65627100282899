import axios from "axios";
import { configValues } from "app-entry";

export const getUser = (payload) => {
  // if (payload.token.accessToken !== undefined) {
  //   let config = {
  //     headers: {
  //       Authorization: `Bearer ${payload.token.accessToken}`,
  //     },
  //   };
  //   try {
  //     return axios.post("CheckUser", payload.user, config);
  //   } catch (e) {
  //     throw new Error(e);
  //   }
  // } else {
  //   throw new Error(payload.token.message);
  // }
};
export const createUser = ({ firstName, lastName }) => {
  return axios.post("Values/users", {
    firstName,
    lastName,
  });
};
export const deleteUser = (userId) => {
  return axios.delete(`/users/${userId}`);
};
export const getUserConsent = () => {
  let baseURL = axios.defaults.baseURL.replace(
    axios.defaults.baseURL,
    configValues.fb.AUTH_URL
  );
  //let scopes = "cardio_fitness%20body%20activity%20heartrate%20location%20nutrition%20oxygen_saturation%20profile%20respiratory_rate%20settings%20sleep%20social%20temperature%20weight%20location";
  let scopes =
    "electrocardiogram%20cardio_fitness%20activity%20heartrate%20location%20nutrition%20oxygen_saturation%20profile%20respiratory_rate%20settings%20sleep%20social%20temperature%20weight%20location";
  /// Implicit grant flow
  let authImplicitFlow = `?response_type=token&client_id=${configValues.fb.CLIENT_ID}&redirect_uri=${configValues.fb.REDIRECT_URL}&scope=${scopes}&expires_in=604800`;
  /// Authorization Grant Flow
  let authGrantFlow = `?response_type=code&client_id=${configValues.fb.CLIENT_ID}&redirect_uri=${configValues.fb.REDIRECT_URL}&scope=${scopes}&expires_in=604800`;
  if (!window.location.hash) {
    window.location.replace(baseURL + authGrantFlow);
  }
};
export const initilizeProcess = (code, signalrid) => {
  let obj = { Token: code, Code: code, SignalRId: signalrid };
  console.log("initilizeProcess", obj);
  try {
    return axios.post("Inner/InitilizeData", obj, {});
  } catch (e) {
    throw new Error(e);
  }
};
export const GetUserStatistics = (encodedId) => {
  let obj = { EncodedId: encodedId };
  try {
    return axios.post("Inner/GetUserStatistics", obj, {});
  } catch (e) {
    throw new Error(e);
  }
};
export const GetAzureMapsAPIKey = () => {
  try {
    return axios.get("Inner/GetAzureMapsAPIKey", {});
  } catch (e) {
    throw new Error(e);
  }
};
export const getExcelFileIntraday = (data) => {
  let config = {
    responseType: "arraybuffer",
  };
  try {
    return axios.post("Inner/getExcelFileIntraday", data, config);
  } catch (e) {
    throw new Error(e);
  }
};
export const GetUserStatisticsForCharts = (
  encodedId,
  dateStart,
  dateEnd,
  optionTable,
  optionTimeField
) => {
  let obj = {
    EncodedId: encodedId,
    StartPeriod: dateStart,
    EndPeriod: dateEnd,
    OptionTable: optionTable,
    OptionTimeField: optionTimeField,
  };
  try {
    return axios.post("Inner/GetUserStatisticsForCharts", obj, {});
  } catch (e) {
    throw new Error(e);
  }
};
export const GetIntraday = (
  endPoint,
  encodedId,
  dateStart,
  dateEnd,
  optionTable,
  optionTimeField
) => {
  let obj = {
    EncodedId: encodedId,
    StartPeriod: dateStart,
    EndPeriod: dateEnd,
    OptionTable: optionTable,
    OptionTimeField: optionTimeField,
  };
  try {
    return axios.post("Inner/" + endPoint, obj, {});
  } catch (e) {
    throw new Error(e);
  }
};
export const downloadFile = () => {
  newAxios({
    method: "get",
    url: "Excel/HelloWorld.xlsx",
    // params: params,
    headers: {
      Authorization: "Bearer " + configValues.token,
    },
    Responsetype: "blob", // binary stream
  }).then(
    (res) => {
      //Process the returned file stream
      const content = res;
      const blob = new Blob([content], {
        type: "application/vnd.ms-excel;charset=utf-8",
      });
      var date =
        new Date().getFullYear() +
        "" +
        (new Date().getMonth() + 1) +
        "" +
        new Date().getDate() +
        "" +
        new Date().getHours() +
        "" +
        new Date().getMinutes() +
        "" +
        new Date().getSeconds() +
        "" +
        new Date().getMilliseconds();
      const fileName = date + ".xlsx";
      if ("download" in document.createElement("a")) {
        //Non ie Download
        const elink = document.createElement("a");
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // release the URL object
        document.body.removeChild(elink);
      } else {
        //Ie10 + download
        navigator.msSaveBlob(blob, fileName);
      }
    },
    (error) => {
      ////  console.log(error);
    }
  );
};
