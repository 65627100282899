import { createSlice } from "@reduxjs/toolkit";
// import theme from "../../src/theme";
import types from "app-redux-types";
const initialState = {
  error: "",
  message: "",
  signalr: {
    connectionState: "",
    connectionId: "",
  },
  codeFitBit: "",
  FitBitUser: "",
  FirstTimer: -1,
  Auth: {
    isAuthenticated: false,
    fullToken: {},
    token: "",
    instance: {},
    scopes: {},
    account: {},
    user: types.user,
    instance: null,
    scopes: {
      scopes: [process.env.REACT_APP_MSAL_API_SCOPES_1],
    },
  },
  fb: {
    CLIENT_ID: process.env.REACT_APP_FITBIT_CLIENT_ID,
    AUTH_URL: process.env.REACT_APP_FITBIT_AUTH_URL,
    REDIRECT_URL: process.env.REACT_APP_FITBIT_REDIRECT_URL,
    TOKEN: "",
  },
};

const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    set: (state, action) => {
      state[action.target] = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setSignalr: (state, action) => {
      state.signalr = action.payload;
    },
    setCodeFitBit: (state, action) => {
      state.codeFitBit = action.payload;
    },
    setFitBitUser: (state, action) => {
      state.FitBitUser = action.payload;
    },
    setFirstTimer: (state, action) => {
      state.FirstTimer = action.payload;
    },
    setAuth: (state, action) => {
      state.Auth = action.payload;
    },
    setToken: (state, action) => {
      state.fb.TOKEN = action.payload;
    },
    setIsAuthenticated: (state, action) => {
      state.Auth.isAuthenticated = action.payload;
    },
  },
});
export const {
  set,
  setError,
  setMessage,
  setSignalr,
  setCodeFitBit,
  setFitBitUser,
  setFirstTimer,
  setAuth,
  setToken,
  setIsAuthenticated,
} = mainSlice.actions;
export default mainSlice.reducer;
