import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

const TransitionAlerts = (props) => {
  const [open, setOpen] = useState(props.open);
  const [message, setMessage] = useState(props.message);
  useEffect(() => {
    if (props.message.length > 0) {
      setOpen(props.open);
      setMessage(props.message);
    }
  }, [props.open, props.message]);
  return open ? (
    <Collapse in={open}>
      <Alert
        variant="outlined"
        severity="error"
        style={{ width: "100%", marginTop: "25px" }}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 1 }}
      >
        {message.split("\n").map((value, index) => {
          return (
            <span key={index}>
              {value}
              <br />
            </span>
          );
        })}
      </Alert>
    </Collapse>
  ) : (
    <span></span>
  );
};
export default TransitionAlerts;
